import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		maxWidth: 800,
		marginTop: theme.spacing(0, 'auto'),
		fontSize: 16,
		'& h1': {
			fontSize: '2.5rem',
			fontWeight: 500,
			marginBottom: theme.spacing(3),
		},
		'& h2': {
			fontSize: '1.75rem',
			fontWeight: 500,
		},
		'& h3': {
			fontSize: '1.5rem',
			fontWeight: 500,
		},
		'& h4': {
			fontSize: '1.25rem',
			fontWeight: 500,
		},
		'& a': {
			color: theme.palette.text.primary,
		},
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: theme.spacing(8),
	},
	title: {
		fontWeight: 500,
	},
	subTitle: {
		fontWeight: '400!important',
		margin: 0,
	},
	article: {},
	logo: {
		height: '65px',
	},
	closeButton: {
		marginLeft: 'auto',
	},
}));
