import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { IconButton } from '@itsa.io/ui';
import darkmodeCtx from 'context/darkmode';
import { Close as CloseIcon } from '@material-ui/icons';
import LogoDark from 'assets/images/logo-dark.svg';
import LogoLight from 'assets/images/logo-light.svg';
import useStyles from 'styles/pages/PrivacyPage';

const PrivacyPage = props => {
	const classes = useStyles();
	const { darkmode } = useContext(darkmodeCtx);

	const handleClose = () => {
		navigate('/');
	};

	const logo = darkmode ? (
		<LogoDark className={classes.logo} />
	) : (
		<LogoLight className={classes.logo} />
	);

	const closeIcon = (
		<IconButton
			className={classes.closeButton}
			color="primary"
			onClick={handleClose}
		>
			<CloseIcon color="primary" />
		</IconButton>
	);

	let headerContent;
	if (!props?.location) {
		headerContent = (
			<header className={classes.header}>
				{closeIcon}
				{logo}
			</header>
		);
	}

	return (
		<div className={classes.root}>
			{headerContent}
			<article>
				<header className={classes.header}>
					<h1 className={classes.title}>Privacy Policy</h1>
					<h3 className={classes.subTitle}>
						Learn more about how Itsawallet collects and uses data and your
						rights as a Itsawallet user.
					</h3>
				</header>

				<p>
					<i>Last Updated Feb 08, 2023</i>
				</p>
				<p>
					Itsawallet respects the privacy of its users and understands the
					importance of secure and private transactions on the blockchain. This
					privacy policy (&#34;Privacy Policy&#34;) explains the data we collect
					and what is collected during transactions on the blockchain. They
					apply to your use of the Itsawallet (&#34;Itsawallet&#34;) promosites,
					Itsawallet browser applications, Itsawallet mobile applications and
					web browser extension (collectively, the &#34;Site&#34;) and the
					related services offered by Itsawallet through the Site.
				</p>
				<p>
					Itsawallet is committed to protecting user (also referred as
					&#34;you&#34; or &#34;your&#34;) privacy as outlined below. This
					document, along with the Terms of Service at{' '}
					<a href="/terms-of-service/" rel="nofollow noreferrer noopener">
						https://app.itsawallet.finance/terms-of-service
					</a>{' '}
					(also referred as &#34;Legal&#34;), outlines different ways that
					Itsawallet will interact with user data. Itsawallet performs the
					actions with data from those who visit or use the Site.
				</p>
				<p>
					User acknowledges and gives consent to this data collection and to the
					Privacy Policy as a whole by using the Site.
				</p>
				<h2>Personal Information: Gathering And Use</h2>
				<p>This is how Itsawallet stores your personal data and preferences:</p>
				<ul>
					<li>
						No personal data is sent or stored on our servers, except when
						interacting with the contact form.
					</li>
					<li>
						When you contact us over the contact form, we will gather all the
						information you fill in order to be able to follow up. Furthermore,
						Google&apos;s Privacy Policy applies as specified at{' '}
						<a
							href="https://policies.google.com/privacy"
							rel="nofollow noreferrer noopener"
						>
							https://policies.google.com/privacy
						</a>
						.
					</li>
					<li>
						Your browser&apos;s local storage may be used to store data that
						personalizes the Site&apos;s user experience.
					</li>
				</ul>
				<p>Local storage may contain the following data:</p>
				<ul>
					<li>Your encrypted screen lock password.</li>
					<li>Settings page preferences.</li>
					<li>Encrypted blockchain connections.</li>
					<li>Encrypted disclaimer flag.</li>
					<li>Introduction notifications flag.</li>
					<li>Custom notifications flag.</li>
					<li>Encrypted address book.</li>
					<li>Encrypted list of custom ERC-20 tokens.</li>
					<li>
						application data needed to enhance the Site&apos;s experience.
					</li>
				</ul>
				<h2>Blockchain Interaction: Gathering And Use</h2>
				<p>
					This section outlines the information collected, used, and stored in
					connection with transactions made on the blockchain.
				</p>
				<p>
					If you interact with the blockchain, by making a signed transaction,
					your actions and data will be stored on the blockchain. The blockchain
					will keep this information disclosed for eternity.
				</p>
				<p>
					<b>Information Collected</b>
				</p>
				<p>
					Transactions made on the Ethereum blockchain are recorded and stored
					on a decentralized network of computers. The following information is
					included in each transaction:
				</p>
				<ul>
					<li>Your Ethereum wallet address.</li>
					<li>The amount of ether (ETH) sent or received.</li>
					<li>The date and time of the transaction.</li>
					<li>The Ethereum blockchain transaction hash.</li>
				</ul>
				<p>
					On the Site, you will mostly be interacting with the blockchain by
					calling a smart contract. Every transaction, except when sending
					native coins, happens by interacting with a smart contract. The
					following information is included when you interact with a smart
					contract:
				</p>
				<ul>
					<li>Any additional smart contract data or parameters.</li>
				</ul>
				<p>
					<b>Use of Information</b>
				</p>
				<p>
					The information collected in Ethereum blockchain transactions is used
					for the following purposes:
				</p>
				<ul>
					<li>To process and complete the transaction.</li>
					<li>
						To store and secure the transaction information on the Ethereum
						blockchain.
					</li>
					<li>
						To provide transparency and accountability for transactions on the
						Ethereum blockchain.
					</li>
				</ul>
				<p>
					<b>Storing Information</b>
				</p>
				<p>
					Transactions made on the Ethereum blockchain are stored on a
					decentralized network of computers and can be viewed by anyone with
					access to the ethereum blockchain explorer. The information is stored
					securely and cannot be altered or deleted.
				</p>
				<p>
					<b>Data Security</b>
				</p>
				<p>
					The Ethereum blockchain uses cryptographic techniques to secure
					transactions and protect against unauthorized access to personal
					information. Despite these efforts, no security measures are 100%
					(hundred percent) effective, and there is always a risk that
					unauthorized parties may access your information. By using the
					Ethereum blockchain, you acknowledge and assume this risk.
				</p>
				<h2>Who Else Will See Peronal Data</h2>
				<p>
					Since the Site does not collect your data, not other than through
					Google forms (&#34;Google Forms&#34;), we can not and will not share
					any personal data.
				</p>
				<p>
					In the case you contact us by Google Forms, the privacy policy of{' '}
					<a
						href="https://policies.google.com/privacy"
						rel="nofollow noreferrer noopener"
					>
						Google Forms
					</a>{' '}
					applies. Itsawallet will never share this information to any party,
					unless enforced by law.
				</p>
				<h2>Security</h2>
				<p>
					The Site uses encryption to protect some of your data in the{' '}
					<a
						href="intercom.help/scoutpad/en/articles/3478364-how-to-clear-local-storage-of-web-browser"
						rel="nofollow noreferrer noopener"
					>
						browser&apos;s localstorage
					</a>
				</p>
				<h2>Fraud, Phishing And Email Scams</h2>
				<p>
					Please be notified that Itsawallet is not in any partnership with
					entities who represent themselves as customer support agents,
					providing customer support services via email and/or social media and
					promising to help solve your issues for money. If you discovered what
					you believe is a fraud, phishing, or scam which impersonates
					Itsawallet, please contact us as soon as possible.
				</p>
				<h2>Changes And Queries</h2>
				<p>
					Itsawallet may, in its sole discretion, modify or revise this Privacy
					Policy at any time, and you agree to be bound by such modifications or
					revisions. Major changes to the Privacy Policy may be notified through
					the Site by a notification message, but you should also periodically
					review the most up-to-date version. Your continued use of Site
					constitutes your acceptance of such changes.
				</p>
				<p>
					To make an inquiry about any of the information listed within this
					privacy policy, you may contact us at{' '}
					<a href="mailto:info@itsawallet.finance">info@itsawallet.finance</a>
				</p>
			</article>
		</div>
	);
};

PrivacyPage.defaultProps = {
	location: null,
};

PrivacyPage.propTypes = {
	location: PropTypes.object,
};

export default PrivacyPage;
